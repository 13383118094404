import React, { FC, ReactElement } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "../App.css";
import logo from "./sword.png";
import { MasterContainer, Container, Avatar } from "../styles/nav";
import styled from "styled-components";

const T = styled.div`
  color: white;
  font-family: "Roboto Serif", sans-serif;
  font-size: 14px;
`;

const NavMain: FC = (): ReactElement => {
  return (
    <MasterContainer>
      <Container>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Link to="/" style={{ textDecoration: "none" }}>
            <img src={logo} alt="Logo" height={47.5} width={47.5} style={{ marginRight: 14 }} />
          </Link>
          <Link style={{ color: "white", textDecoration: "none" }} to="/drafts">
            <T>Drafts</T>
          </Link>
        </div>
      </Container>
    </MasterContainer>
  );
};

export default NavMain;
