import React, { FC, ReactElement } from "react";
import { Version, Header, SubTitle, TinyTitle } from "../styles/home";
import HeroForm from "./HeroForm";
import styled from "styled-components";
import appStoreButton from "./appstorebutton.png";

const HeroContainer: FC = (): ReactElement => {
  return (
    <CoverTextDiv>
      <Header>
        <span>UpskillHero</span>
        {/* <span style={{ fontSize: 11, marginLeft: 10 }}>(Now available worldwide)</span> */}
      </Header>
      <SubTitle>
        <span style={{ color: "#ffffff", fontSize: 30 }}>
          <b>A different type of career assessment</b>–answer self discovery prompts, tackle playful interest challenges, get guidance on how to kickstart a new career.
        </span>
      </SubTitle>
      <AppStoreButtonContainer>
        <a href="https://apps.apple.com/us/app/upskillhero/id6471110771" target="_blank">
          <AppStoreButton src={appStoreButton} alt="Download on the App Store" />
        </a>
      </AppStoreButtonContainer>
    </CoverTextDiv>
  );
};

export default HeroContainer;

const CoverTextDiv = styled.div`
  width: 44.5%;
  @media (max-width: 1000px) {
    width: 90%;
  }
  @media (max-width: 600px) {
    width: 90%;
    margin-top: 30px;
  }
`;

const AppStoreButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

const AppStoreButton = styled.img`
  width: 300px; /* Adjust the size as needed */
  cursor: pointer;
  @media (max-width: 600px) {
    width: 180px; /* Adjust size for smaller screens */
  }
`;
